body,
div,
span,
h1,
a,
i,
form,
label {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
body {
  line-height: 1;
}
body {
  background: #e9e9e9;
  color: #666666;
  font-family: "RobotoDraft", "Roboto", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .pen-title {
  padding: 50px 0 10px;
  text-align: center;
  letter-spacing: 2px;
}
.pen-title h1 {
  margin: 0 0 20px;
  font-size: 48px;
  font-weight: 300;
  color: #3d464d;
} */
.loginMain .logo {
  text-align: center;
}
.loginMain .container {
  position: relative;
  max-width: 460px;
  min-width: 460px;
  width: 100%;
  /* margin: 0 auto 100px; */
}
.card {
  position: relative;
  background: #ffffff;
  border-radius: 5px;
  padding: 40px 0;
  box-sizing: border-box;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: 0.3s ease;
}
.card .title {
  position: relative;
  z-index: 1;
  border-left: 5px solid #6c757d;
  margin: 0 0 35px;
  padding: 10px 0 10px 30px;
  color: #6c757d;
  font-size: 32px;
  font-weight: 600;
  text-transform: uppercase;
}
.card .input-container {
  position: relative;
  margin: 0 30px 30px;
}
.card .button-container {
  margin: 0 60px;
  text-align: center;
}
.card .button-container button {
  background: transparent;
  color: #6c757d;
  width: 150px;
  border: 2px solid #6c757d;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s ease;
  border-radius: 4px;
}
.card .button-container button:hover {
  background: #6c757d;
  color: #fff;
}
.card .button-container button:disabled {
  background: #6c757d;
  opacity: 0.5;
}
.card .footer {
  margin: 20px 0 0;
  color: #bababa;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
}
.card .footer a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s ease;
  font-size: 18px;
}
.card .footer a:hover {
  color: #757575;
}
.card .toggle {
  position: absolute;
  background: #5d3fd3;
  width: 100px;
  height: 100px;
  top: 8%;
  right: -10%;
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  color: #ffffff;
  font-size: 0px;
  line-height: 145px;
  text-align: center;
}
.card .toggle svg {
  font-size: 45px;
}
.card .toggle:before {
  content: "";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

.loginMain {
  margin: auto;
  background-image: url(../../images/login_bg.svg);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: bottom;
  height: 100vh;
  display: grid;
  place-content: center;
  row-gap: 30px;
}

@media (max-width: 480px) {
  .loginMain .container {
    min-width: 340px;
  }
}

.captchaImg {
  margin: 5px auto;
  padding: 12px 0;
  background: linear-gradient(
      45deg,
      rgba(221, 221, 238, 0.5) 12%,
      transparent 0,
      transparent 88%,
      rgba(221, 221, 238, 0.5) 0
    ),
    linear-gradient(135deg, transparent 37%, rgba(170, 170, 187, 0.5) 0, rgba(170, 170, 187, 0.5) 63%, transparent 0),
    linear-gradient(45deg, transparent 37%, rgba(221, 221, 238, 0.5) 0, rgba(221, 221, 238, 0.5) 63%, transparent 0),
    #c4c4c4;
  background-size: 40px 40px;
  width: 75%;
  height: 100%;
  position: relative;
  user-select: none;
}

.captchaImg h2 {
  color: #464646;
  font-family: "Fredericka the Great", cursive;
  margin: 0 auto;
  display: table;
  margin: 0 auto;
  font-size: 35px;
}

.captchaImg button {
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  border: none;
  bottom: 5px;
  right: 5px;
  transition: 0.5s all;
  width: 30px;
  height: 30px;
  line-height: 45px;
  padding: 0;
}

.captchaImg button:hover {
  background: rgba(255, 255, 255, 1);
}

.captchaImg button:focus {
  outline: none;
}

/* globalLoader */

.loader {
  width: 32px;
  height: 32px;
  position: relative;
  border-radius: 50%;
  color: #ff3d00;
  animation: fill 1s ease-in infinite alternate;
}
.loader::before,
.loader::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  left: 48px;
  top: 0;
  animation: fill 0.9s ease-in infinite alternate;
}

.loader::after {
  left: auto;
  right: 48px;
  animation-duration: 1.1s;
}

@keyframes fill {
  0% {
    box-shadow: 0 0 0 2px inset;
  }
  100% {
    box-shadow: 0 0 0 10px inset;
  }
}
