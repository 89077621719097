@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import url("react-toastify/dist/ReactToastify.css");

:root {
  --primary-800: #e32c2b;
  --primary-main: #e32b29;
  --secondary-main: #009393;
  --secondary-800: #5f9ea0;
  --white-800: #e9ecef;
  --table-header: #6c757d;
  --table-column: #e9ecef;
  --white: #ffffff;
  --black: #000000;
  --sticky-gradient : linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.08) 100%);
}

html,
body {
  scroll-behavior: smooth;
}

body {
  height: 100vh;
}

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

a {
  cursor: pointer !important;
}

.decryptTextarea {
  padding: 16px;
  border-color: #d0d7de;
  border-radius: 4px;
  resize: vertical;
  min-height: 50px;
  width: 100%;
}

.app {
  display: flex;
  position: relative;
}

.max-width-100 {
  max-width: 100% !important;
}

.css-f3jnds-MuiDataGrid-columnHeaders {
  color: var(--white) !important;
}

.css-hpjhlg-MuiTablePagination-root {
  color: var(--white) !important;
}

.cardBody {
  padding: "20px";
}

.danger {
  color: #ff6156;
  margin: 0 4px;
}

.dark {
  color: #6ba093;
  margin: 0 4px;
}

.blue {
  margin: 0 4px;
}

.modal-header {
  font-size: 18px !important;
  color: #158582;
}

/* CUSTOM CSS START */

.list-style-none {
  list-style: none !important;
}

.lh-0 {
  line-height: 0 !important;
}

.d-block {
  display: block !important;
}

.MuiDataGrid-columnHeader,
.MuiTablePagination-root,
.MuiDataGrid-columnHeader .MuiSvgIcon-root,
.MuiTablePagination-root .MuiSvgIcon-root {
  color: var(--white) !important;
}

.MuiDataGrid-columnHeader:focus-within,
.css-oc39s6-MuiDataGrid-root .MuiDataGrid-cell:focus-within,
.MuiDataGrid-cell:focus {
  outline: 0 !important;
}

.pro-inner-item:hover,
.pro-menu-item.active {
  color: #0e144c !important;
}

.pro-sidebar {
  height: 100vh !important;
  color: rgb(20, 20, 20);
}

@media (min-width: 992px) {
  .pro-sidebar-toggle-btn {
    display: none !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  background-color: transparent !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--black) !important;
}

.modalPadding {
  padding: 8px;
}

.app .btn-toggle {
  cursor: pointer;
  width: 35px;
  height: 35px;
  color: #747474;
  background: #d7d7d7;
  text-align: center;
  border-radius: 50%;
  display: none;
  justify-content: center;
  align-items: center;
}

.MuiDataGrid-selectedRowCount {
  visibility: hidden !important;
}

.beforeTree {
  display: flex;
  align-items: center;
}

.reactSelectFocus .react-select__control--is-focused,
.reactSelectFocus .react-select__control--is-focused:hover,
.reactSelectFocus .css-94h981-control,
.reactSelectFocus .css-94h981-control:hover {
  border-color: var(--black);
  box-shadow: 0 0 0 1px var(--black);
}
.react-select__menu{
  z-index: 150 !important;
  }
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-help {
  cursor: help !important;
}

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3 !important;
}

@media (max-width: 600px) {
  .MuiDialogContent-root {
    padding: 16px !important;
  }
}

@media (max-width: 768px) {
  .responsive-head {
    width: 100%;
  }

  .app .btn-toggle {
    display: inline-flex;
  }
}

.react-checkbox-tree .MuiSvgIcon-root {
  margin-bottom: -5px;
}

.rct-collapse {
  padding: 0;
  width: 24px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}

button {
  cursor: pointer;
}

.MuiDataGrid-virtualScroller {
  font-size: 16px;
}

.addressIndex {
  position: absolute;
  background-color: var(--white);
  padding: 0 4px;
  top: -13px;
  left: 10px;
  border: 1px solid #c4c4c4;
  border-radius: 0.25rem;
}

.addressIndex-2 {
  position: absolute;
  background-color: var(--white);
  padding: 0 4px;
  top: -13px;
  left: 10px;
  border: 1px solid #c4c4c4;
  border-radius: 0.25rem;
  font-size: 14px;
}

.addressDeleteIcon {
  color: #adadad;
  position: absolute;
  right: 10px;
  top: -13px;
  background: var(--white);
  transition: 0.5s all;
  border: 1px solid #c4c4c4;
  border-radius: 0.25rem;
}

.addressDeleteIcon:hover {
  color: #8d8d8d;
}

.productViewDesc p {
  margin-top: 0;
}

.topBar {
  position: sticky;
  top: 0;
  background-color: var(--white) !important;
  z-index: 999;
  margin-bottom: 32px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media (max-width: 480px) {
  .topBar {
    position: relative;
  }
}

.fullscreen {
  position: relative;
  z-index: 1302;
}

.MuiDataGrid-footerContainer {
  border-radius: 0 0 4px 4px;
}

.notificationBadge span {
  background: #f44336;
  font-weight: bold;
  color: var(--white);
}

.accordianNotificationBadge {
  background: #f44336;
  font-weight: bold;
  color: var(--white);
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 15px;
  font-size: 12px;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 3px solid #f44336;
}

.orderTableSelect fieldset {
  border: none;
}

.orderTableSelect>div {
  padding-left: 0;
}

.userMenu div:nth-child(3) {
  width: 210px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.z-index-1 {
  position: relative;
  z-index: 1;
}

.z-index-2 {
  position: relative;
  z-index: 2;
}

.z-index-3 {
  position: relative;
  z-index: 3;
}

.z-index-4 {
  position: relative;
  z-index: 4;
}

.z-index-5 {
  position: relative;
  z-index: 5;
}

.z-index-6 {
  position: relative;
  z-index: 6;
}

.z-index-7 {
  position: relative;
  z-index: 7;
}

.z-index-8 {
  position: relative;
  z-index: 8;
}

.z-index-9 {
  position: relative;
  z-index: 9;
}

.z-index-10 {
  position: relative;
  z-index: 10;
}

.z-index-11 {
  position: relative;
  z-index: 11;
}

.z-index-12 {
  position: relative;
  z-index: 12;
}

.z-index-13 {
  position: relative;
  z-index: 13;
}

.z-index-14 {
  position: relative;
  z-index: 14;
}

.react-datepicker-popper {
  position: relative;
  z-index: 11 !important;
}

.img-product {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  max-width: 100%;
}

.img-sub-product {
  border: 1px solid #ddd;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 60px;
  height: 42px;
  object-fit: cover;
}

.no-img-sub-product {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 60px;
  height: 42px;
  object-fit: cover;
}

.logo {
  max-width: 100%;
}

.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within {
  outline: none !important;
  outline-offset: 0 !important;
}

[data-field="actions"] {
  padding-left: 0 !important;
}
.MuiTablePagination-actions .Mui-disabled{
opacity: 0.2;
}
.searchBar {
  width: 400px;
}

.searchBar .search_input input {
  padding: 0 14px !important;
}

.searchBar .searchBtn {
  padding: 0 8px !important;
}

.orderSearchTabPanel1 {
  overflow: auto;
}

.orderSearchTabPanel1>div {
  padding-top: 0;
}

.searchClearBtn {
  padding: 0 !important;
}

.searchClearBtn svg {
  font-size: 18px !important;
}

.imageAlertThumb .MuiPaper-root {
  margin: 0 !important;
}

.react-select-labels {
  position: absolute;
  top: -9px;
  left: 9px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  background: var(--white);
  padding: 0 5px;
}

.react-select-labels-1 {
  position: absolute;
  top: -9px;
  left: 9px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  background: #f8f9fa;
  padding: 0 5px;
}

.react-select-labels-2 {
  position: absolute;
  top: -9px;
  left: 9px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  background: #f2f0f0;
  padding: 0 5px;
}

.fileUploaderLabel,
.fileUploaderLabel-1,
.fileUploaderLabel-11,
.fileUploaderLabel-2 {
  position: absolute;
  right: 9px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  background: var(--white);
  padding: 0 5px;
}

.fileUploaderLabel {
  top: -9px;
}

.fileUploaderLabel-1 {
  top: -22%;
}

.fileUploaderLabel-11 {
  top: -10%;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 14px !important;
}

.MuiTooltip-popper .MuiTooltip-arrow {
  left: 50% !important;
  transform: translate(-50%, 0) !important;
}

.createOrderSelectAddress .react-select__control {
  background-color: var(--white);
}

.searchBar {
  background-color: var(--white) !important;
}

.MuiInputBase-inputSizeSmall {
  padding: 11.5px 14px !important;
}

.tree-price-input .MuiInputBase-inputSizeSmall {
  padding: 8px 14px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* button:disabled, */
input:disabled,
input[readonly] {
  cursor: not-allowed;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.6) !important;
}

/* button:disabled {
  -webkit-text-fill-color: var(--white) !important;
} */

/* button:disabled:hover {
  -webkit-text-fill-color: var(--white) !important;
  background-color: #3d464d;
} */

button.MuiButton-outlined:disabled {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.6) !important;
}

.radioPad .MuiButtonBase-root {
  padding: 0 8px;
}

.createOrderDialog {
  min-height: 80vh;
  height: 100%;
}

.createOrderDialogFooter {
  border-top: 1px solid #d5d4d4;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 16px !important;
  background: var(--white);
  z-index: 20;
}

.addPaymentSelect .MuiSelect-select {
  padding: 11.5px 14px;
}

.orderViewStatusSelect .orderTableSelect>div {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 20px !important;
}

.orderViewStatusSelect .orderTableSelect>svg {
  top: 0;
  right: -5px;
}

.quickActionBtn {
  background: #f2f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 4px;
  padding: 8px;
  border-radius: 4px;
  transition: 0.5s all;
  cursor: pointer;
}

.quickActionBtn:hover {
  background-color: #dadada;
}

.inputGroupTextField .MuiInputBase-root {
  border-radius: 4px 0 0 0;
}

.inputGroupDateField .MuiInputBase-root {
  border-radius: 0 4px 0 0;
}

.inputGroupButton {
  border-radius: 0 0 4px 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3d464d;
  color: var(--white);
  padding: 0 15px;
  justify-content: space-between;
  align-items: center;
}

#inputGroupButtonWImg label,
#inputGroupButtonWImg .custAddImg {
  padding: 0 15px;
}

#inputGroupButtonWImg .custAddImg {
  position: relative;
  display: flex;
  z-index: 1;
  cursor: pointer;
}

#inputGroupButtonWoImg label {
  width: 100%;
  padding: 0;
  text-align: center;
}

.customInputFileField {
  display: none !important;
}

.inputGroupButtonLabels {
  border-radius: 4px 0 0 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c4c4c4;
  color: #040509;
  padding: 0 15px;
  height: 100%;
  width: 100%;
}

.inputGroupButtonInputs .MuiInputBase-root {
  border-radius: 0 4px 4px 0 !important;
}

.inputGroupButtonCustom {
  border-radius: 0 4px 4px 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3d464d;
  color: var(--white);
  padding: 0 15px;
  height: 100%;
  width: 100%;
  border: none;
  transition: 0.5s all;
}

.inputGroupButtonCustom:hover {
  background: #3d426f;
  color: var(--white);
}

.inputGroupButtonCustomInputs .MuiInputBase-root {
  border-radius: 4px 0 0 4px !important;
}

.jodit-status-bar .jodit-status-bar__item-right:last-child {
  display: none;
}

@media (max-width: 768px) {
  .searchBar {
    width: 100%;
  }
}

.dashboardCardIcon svg {
  position: absolute;
  top: 50%;
  right: 10px;
  color: #8f8f91;
  font-size: 80px;
  transform: translateY(-50%) rotate(-30deg);
  transition: 0.5s all;
}

.dashboardCardLinks:hover .dashboardCardIcon svg {
  transform: translateY(-50%) rotate(0deg);
}

/* Customer view profile page customer level tags CSS start */

.customer-medal {
  position: relative;
  cursor: default;
  user-select: none;
}

.customer-medal__circle {
  font-size: 20px;
  font-weight: 600;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  color: var(--white);
  text-align: center;
  line-height: 28px;
  vertical-align: middle;
  position: relative;
  border-width: 0.2em;
  border-style: solid;
  z-index: 1;
}

.customer-medal__circle.customer-medal__circle--platinum {
  box-shadow: inset 0 0 0 #e0e4e9, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #dadce7;
  text-shadow: 0 0 4px #cacfd6;
  background: linear-gradient(to bottom right, #cbcfd6 50%, #b8bdca 50%);
}

.customer-medal__circle.customer-medal__circle--gold {
  box-shadow: inset 0 0 0 #b67d05, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #fadd40;
  text-shadow: 0 0 4px #9d6c04;
  background: linear-gradient(to bottom right, #f9ad0e 50%, #e89f06 50%);
}

.customer-medal__circle.customer-medal__circle--silver {
  box-shadow: inset 0 0 0 #a7b2b8, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #edeff1;
  text-shadow: 0px 0px 4px #98a6ad;
  background: linear-gradient(to bottom right, #d1d7da 50%, #c3cbcf 50%);
}

.customer-medal__circle.customer-medal__circle--bronze {
  box-shadow: inset 0 0 0 #955405, 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-color: #f7bb23;
  text-shadow: 0 0 4px #7d4604;
  background: linear-gradient(to bottom right, #df7e08 50%, #c67007 50%);
}

.customer-medal__ribbon {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-width: 6px 8px;
  width: 0;
  height: 16px;
  top: 32px;
}

.customer-medal__ribbon--left {
  left: 0;
  border-color: #fc402d #fc402d transparent #fc402d;
  transform: rotate(20deg) translateZ(-32px);
}

.customer-medal__ribbon--right {
  left: 20px;
  border-color: #f31903 #f31903 transparent #f31903;
  transform: rotate(-20deg) translateZ(-48px);
}

/* Customer view profile page customer level tags CSS end */

/* Scrollbar css start */

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--white-800);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #818183;
}

@media print {
  body.barcode * {
    visibility: hidden;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

/* Scrollbar css end */

#ReactSimpleImageViewer {
  z-index: 1010;
  margin-left: 0 !important;
}

.ql-editor {
  min-height: 100px;
}

.text-capitalize {
  text-transform: capitalize;
}

.customFileInput input[type="file"] {
  padding: 11px 4px 12px 0 !important;
}

.customFileInputButton input[type="file"]::file-selector-button {
  border: 1px solid #c4c4c4;
  color: rgba(0, 0, 0, 0.6);
  outline: none;
  border-radius: 4px;
  background-color: #eeeeee;
  transition: 0.5s all;
  margin-right: 6px;
  cursor: pointer;
}

.customFileInputButton-1 input[type="file"]::file-selector-button {
  padding: 14px 10px;
  margin-top: -12px;
}

.customFileInputButton-2 input[type="file"]::file-selector-button {
  padding: 15px 10px;
  margin-top: -10px;
  position: absolute;
  left: 0;
  top: 10px;
}

.notificationAccDetails {
  padding: 0 !important;
  overflow-y: auto;
  max-height: calc(100vh - 80px - (3 * 48px));
}

.notifictionAccHead {
  background-color: #3d464d !important;
  color: var(--white) !important;
}

@media (max-width: 480px) {
  #notificationDrawer {
    width: 85vw;
  }
}

.datePickerStyling,
.datePickerStyling-1 {
  font-weight: 400;
  font-size: 1rem;
  color: #333 !important;
  padding: 13px 14px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #c4c4c4;
  width: 100%;
  height: 100%;
}

.datePickerStyling {
  border-radius: 0 4px 0 0;
}

.datePickerStyling-1 {
  border-radius: 4px;
}

.react-datepicker__month {
  user-select: none;
}

.dashboardTopCards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  gap: 19px;
}

@media (max-width: 1766px) {
  .dashboardTopCards {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
}

@media (max-width: 1600px) {
  .dashboardTopCards {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 1400px) {
  .dashboardTopCards {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }
}

.searchProductTab .MuiTabs-vertical,
.searchProductTab div[role="tabpanel"] {
  overflow-y: auto;
  max-height: 80vh;
}

.searchProductTableBody tr:last-child td {
  border-bottom: none !important;
}

.navLinks {
  color: #fffade;
  text-decoration: none;
}

.functionalKey {
  background-color: #f0f0f0;
  border: 1px solid var(--black);
  padding: 0 20px 2px;
  border-radius: 2px;
  font-size: 14px;
  user-select: none;
  pointer-events: none;
}

.mobModalFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 480px) {
  .mobModalFooter {
    flex-direction: column;
    justify-content: center;
    gap: 6px;
  }
}

@media print {
  .pagebreak {
    page-break-after: always;
  }

  /* page-break-after works, as well */
}

.orderSearchProductDialog {
  padding-bottom: 16px !important;
}

.searchProductDialog .MuiDialog-container .MuiPaper-root {
  max-height: none !important;
}
.mr-2-px{
  margin-right: 2px;
}
/* CUSTOM CSS END */

/* media queries */

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1800px) {}

.sub-product-card {
  padding: 16px;
}

.sub-product-card:not(:last-child) {
  border-bottom: 1px solid #adb5bd;
}

.product-card {
  border-radius: 0.625rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  border: 1px solid #f1f1f4;
  border-color: #f1f1f4;
  background-color: #f8f9fa !important;
  overflow: visible !important;
}

.css-cm6y85-MuiTypography-root {
  font-size: 1rem !important;
}

.css-mntmfr-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #330061 !important;
}

.css-185gdzj-MuiCardHeader-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 16px !important;
}

.icon-check {
  background: var(--black);
  color: var(--white);
  height: 30px;
  width: 30px;
  margin-top: 8px;
}

.dragDropFile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.dragDropFile input {
  position: absolute;
  top: 0;
  right: 0;
  width: 200%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.ticketPhoto {
  position: relative;
  margin: 4px;
  width: 100px;
  height: 100px;
}

.thumbNailPhoto {
  position: relative;
  margin: auto;
  overflow-x: auto;
  max-width: 300px;
}

.colorPhoto button,
.ticketPhoto button,
.thumbNailPhoto button,
.youtubeurl button {
  position: absolute;
  right: 4px;
  top: 6px;
  background-color: var(--white);
  color: var(--primary-800);
  width: 30px;
  height: 30px;
  border-radius: 4px;
  padding: 4px;
  transition: 0.5s all;
}

.colorPhoto img {
  max-width: 100%;
  max-height: 150px;
  display: block;
}

.ticketPhoto img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
}

.productPhoto {
  position: relative;
  width: fit-content;
  margin: auto;
}

.productPhoto img {
  width: 100%;
  max-width: 320px;
  max-height: 300px;
  display: block;
}

.stickyButton {
  padding: 1rem 15px;
  margin-top: 1rem;
  background: var(--white);
  border-top: 1px solid #e0e0e0;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 1111;
}

.product-table {
  background: var(--white-800);
}

.product-table thead tr {
  background: #6c757d;
}

.product-table thead tr th {
  color: var(--white);
}

.product-table tbody tr {
  background: var(--white-800);
  border-bottom: 1px solid #e0e0e0;
}

.product-table tbody tr:last-child {
  border-bottom: none;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  left: -20px;
}

.sliderPad {
  padding: 0 8px 24px;
}

button.disabledTab:disabled {
  color: rgba(4, 5, 9, 0.2) !important;
  -webkit-text-fill-color: rgba(4, 5, 9, 0.2) !important;
}

.autocompleteInput input {
  padding: 5.5px 4px 5.5px 8px !important;
}

.phoneInput input {
  padding-left: 0 !important;
}

.productTree .react-checkbox-tree {
  flex-direction: column;
}

.customProductTree label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.customProductTree label:hover {
  background: transparent;
}

.rct-node-parent.customProductTree .rct-text {
  margin: 12px 0;
}

.rct-node-leaf.customProductTree .rct-text {
  margin: 8px 0;
}

.customProductTree label img {
  display: block !important;
  width: 50px !important;
  height: 50px !important;
  object-fit: contain;
  border: 1px solid var(--white-800);
}

.customFileInputButtonProduct {
  padding: 16px;
  color: var(--black);
  font-style: italic;
  border: 2px dashed #a3a3a3;
  text-align: center;
  cursor: pointer;
}

#subProductImages,
#subProductImages+fieldset {
  display: none;
}
#subProductLargeImages,
#subProductLargeImages+fieldset {
  display: none;
}

#productImages,
#productImages+fieldset {
  display: none;
}

#sliderImage,
#sliderImage+fieldset {
  display: none;
}

.supportFilterDate {
  padding: 8.5px 32px 8.5px 14px;
}

.supportDataCards {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.supportCard {
  width: 100%;
  float: left;
  padding: 12px;
}

@media (max-width: 1300px) {
  .supportCard {
    width: 100%;
  }
}

@media (max-width: 950px) {
  .supportCard {
    width: 100%;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .supportCard {
    width: 100%;
  }
}

.customTextarea {
  padding: 16px 50px 16px 16px;
  border-color: #d0d7de;
  border-radius: 4px;
  resize: vertical;
  min-height: 50px;
  width: 100%;
}

.customTextarea:hover {
  border-color: #141414;
}

.attachFileBtn {
  border: 1px solid #dee2e6;
  background-color: #dee2e6;
  border-radius: 50%;
  padding: 4px;
  transition: 0.5s all;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.attachFileBtn:hover {
  background-color: transparent;
  border-color: #dee2e6;
}

.attachFileBtn svg {
  transform: rotate(-45deg);
  transition: 0.5s all;
}

.attachFileBtn:hover svg {
  color: var(--secondary-main);
}

.chatImgDeleteBtn {
  background: #e9f4ff !important;
  border-radius: 50% !important;
  padding: 2px !important;
  position: absolute !important;
  top: 4px;
  right: 4px;
  z-index: 2;
  transition: 0.5s all;
}

.chatImgDeleteBtn:hover {
  background-color: #e0e0e0 !important;
}

.treeOddBg {
  background-color: #f8f9fa !important;
  border-top: 1px dashed var(--white);
  border-bottom: 1px dashed var(--white);
}

.treeSubRow {
  width: 100%;
  padding-left: 60px !important;
}

.treeValue {
  display: flex;
  align-items: center;
  gap: 12px;
}

@media (max-width: 575px) {
  .treeSubRow {
    padding-left: 0 !important;
  }
}

.text-truncate,
.oneLineEllipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.twoLineEllipse {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.threeLineEllipse {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.react-datepicker__close-icon::after {
  content: \2715;
  cursor: pointer;
  background-color: transparent;
  color: #ccc;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 4px;
  font-size: 24px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.customCard {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 4px;
  padding: 16px;
}

.customMenu .MuiPaper-root {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.customMenu .MuiList-root {
  min-width: 100px;
}

.customMenuItem {
  padding: 6px 16px;
}

.customMenuItem:not(:last-child) {
  margin-bottom: 4px;
}

.customMenuItem .pro-inner-item .pro-item-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.dashCardIcon {
  position: absolute;
  left: 15px;
  font-size: 30px !important;
  top: 10px;
}

.profileThumbImage {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.productDetailHead {
  width: 100%;
  float: left;
}

.productDetailHeadInner {
  width: 20%;
  float: left;
  padding: 8px 4px;
}

@media (max-width: 1600px) {
  .productDetailHeadInner {
    width: 33%;
  }
}

@media (max-width: 575px) {
  .productDetailHeadInner:nth-child(1) {
    width: 100%;
  }

  .productDetailHeadInner {
    width: 50%;
  }
}

@media (max-width: 400px) {
  .productDetailHeadInner {
    width: 100%;
  }
}

.track-progress {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.track-progress li {
  list-style-type: none;
  border-top: 4px solid var(--white-800);
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  text-align: left;
  padding: 10px;
}

.track-progress li:not(:last-child) {
  border-bottom: 2px dashed var(--white);
}

.track-progress li.done {
  border-left: 4px solid var(--primary-main);
}

.track-progress li .checkMark {
  width: 30px;
  height: 30px;
  border: 4px solid var(--white-800);
  background: var(--white);
  border-radius: 50%;
  font-weight: 700;
  color: var(--white);
  border: 4px solid var(--primary-main);
  background: var(--primary-main);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
  transform: none;
  text-align: center;
}

.track-progress-1 {
  display: flex;
  align-items: flex-start;
  padding-top: 8px;
  padding-left: 0;
  margin-bottom: 0;
}

.track-progress-1 li {
  list-style-type: none;
  border-top: 4px solid var(--white-800);
  display: inline-block;
  position: relative;
  text-align: center;
  padding: 10px;
  width: 100%;
}

.track-progress-1 li.done {
  border-top: 4px solid var(--primary-main);
}

.track-progress-1 li .checkMark {
  position: absolute;
  top: -2px;
  width: 30px;
  height: 30px;
  border: 4px solid var(--white-800);
  background: var(--white);
  border-radius: 50%;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  font-weight: 700;
  color: var(--white);
  border: 4px solid var(--primary-main);
  background: var(--primary-main);
  display: flex;
  justify-content: center;
  align-items: center;
}

.productThumbImg {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border: 1px solid #dee2e6;
}

.inputGroupTextField .MuiInputBase-root {
  border-radius: 4px 4px 0 0;
}

.inputGroupDateField .MuiInputBase-root {
  border-radius: 0 4px 0 0;
}

.inputGroupButton {
  border-radius: 0 0 4px 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3d464d;
  color: var(--white);
  padding: 0 15px;
  justify-content: space-between;
  align-items: center;
}

#inputGroupButtonWImg label,
#inputGroupButtonWImg .custAddImg {
  padding: 0 15px;
}

#inputGroupButtonWImg .custAddImg {
  position: relative;
  display: flex;
  z-index: 1;
  cursor: pointer;
}

#inputGroupButtonWoImg label {
  width: 100%;
  padding: 0;
  text-align: center;
}

.customInputFileField {
  display: none !important;
}

.leftJoinedField .react-select__control {
  border-radius: 4px 0 0 4px;
}

.rightJoinedField .MuiInputBase-root {
  border-radius: 0 4px 4px 0;
}

[placeholder] {
  text-overflow: ellipsis;
}

.react-select__placeholder {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  white-space: pre;
}

.customUploadButtonWithText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 6px;
}

/* Custom card class */
.customCard {
  overflow: unset !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  border-radius: 4px;
}

.customCardMargin {
  margin: 16px 0;
}

/* Custom table class */
.customTable thead tr th {
  background-color: #6c757d !important;
  color: var(--white) !important;
  font-weight: 600 !important;
}

.customTable thead tr th:first-child {
  border-radius: 4px 0 0 0;
}

.customTable thead tr th:last-child {
  border-radius: 0 4px 0 0;
}

.customTable tbody tr td {
  background-color: #f8f9fa !important;
}

.customTable tbody tr:last-child td {
  border: none;
}

.customTable tbody tr:last-child td:first-child {
  border-radius: 0 0 0 4px;
}

.customTable tbody tr:last-child td:last-child {
  border-radius: 0 0 4px 0;
}

/* Customer profile page */
.customerShippingAddress {
  border: 1px solid var(--white-800);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.05) 2px 2px 3px;
  height: 100%;
}

.customerShippingAddress .content {
  padding: 8px;
  position: relative;
  z-index: 2;
}

.customerShippingIcon {
  position: absolute;
  font-size: 90px !important;
  color: var(--white-800);
  bottom: -18px;
  right: -18px;
  transform: rotate(-45deg);
}

.shippingAddressDefaultTag {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  border-radius: 0 0 0 4px;
  padding: 2px 5px;
  font-size: 12px;
  color: var(--white);
  font-weight: bold;
  background: var(--primary-800);
}

.customerCountsCard {
  position: relative;
  overflow: hidden;
  border-right: 3px solid var(--secondary-main);
  border-radius: 4px;
}

.customerCountsCard .content {
  padding: 10px;
  text-align: right;
  position: relative;
  z-index: 1;
}

.customerCountsCardIcon {
  position: absolute;
  font-size: 30px !important;
  top: 35%;
  left: 13px;
  transform: translateY(-50%);
  color: var(--secondary-800);
}

.imageContain {
  object-fit: contain;
  max-width: 100%;
}

.thumbImage {
  width: 72px;
  height: 72px;
  padding: 2px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin: 2px;
  object-fit: contain;
  max-width: 100%;
}

.pdfDoc {
  display: block;
  margin: 2px;
  padding: 2px;
}

.pdfDoc .MuiPaper-root {
  margin: 0 !important;
  width: fit-content;
  padding: 0 12px;
}

.pdfDoc .MuiPaper-root .MuiAlert-icon {
  margin-right: 0px;
  padding: 12px 0;
  font-size: 40px;
}

.profileBgPattern {
  background-color: var(--secondary-main);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg stroke='%23000000' stroke-width='26.6' stroke-opacity='0.02' %3E%3Ccircle fill='%23009393' cx='0' cy='0' r='1800'/%3E%3Ccircle fill='%230d8d8d' cx='0' cy='0' r='1700'/%3E%3Ccircle fill='%231b8787' cx='0' cy='0' r='1600'/%3E%3Ccircle fill='%23288181' cx='0' cy='0' r='1500'/%3E%3Ccircle fill='%23357b7b' cx='0' cy='0' r='1400'/%3E%3Ccircle fill='%23437574' cx='0' cy='0' r='1300'/%3E%3Ccircle fill='%23506f6e' cx='0' cy='0' r='1200'/%3E%3Ccircle fill='%235d6968' cx='0' cy='0' r='1100'/%3E%3Ccircle fill='%236b6362' cx='0' cy='0' r='1000'/%3E%3Ccircle fill='%23785c5c' cx='0' cy='0' r='900'/%3E%3Ccircle fill='%23865656' cx='0' cy='0' r='800'/%3E%3Ccircle fill='%23935050' cx='0' cy='0' r='700'/%3E%3Ccircle fill='%23a04a4a' cx='0' cy='0' r='600'/%3E%3Ccircle fill='%23ae4443' cx='0' cy='0' r='500'/%3E%3Ccircle fill='%23bb3e3d' cx='0' cy='0' r='400'/%3E%3Ccircle fill='%23c83837' cx='0' cy='0' r='300'/%3E%3Ccircle fill='%23d63231' cx='0' cy='0' r='200'/%3E%3Ccircle fill='%23E32C2B' cx='0' cy='0' r='100'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  height: 150px;
  border-radius: 4px 4px 0 0;
  opacity: 0.8;
}

.profilePicture {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 5px solid #f0f0f0;
  object-fit: contain;
  margin-top: -135px;
  background: var(--white);
  position: relative;
  z-index: 1;
}

.profilePicture-parent {
  display: flex;
  justify-content: center;
}

.circle-skeleton {
  background-color: var(--white);
  margin-top: -135px;
  z-index: 1;
}

.dashboard-card-summary {
  text-decoration: none;
  color: currentColor;
  transition: all 0.5 ease-in-out;
}

.dashboard-card-summary:hover .customCard {
  transition: scale 0.5 ease-in-out;
  scale: 1.05;
}

/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: var(--white) !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}

.MuiDataGrid-cell--withRenderer span,
.MuiDataGrid-cell--withRenderer p,
.MuiDataGrid-cell--withRenderer a,
.MuiDataGrid-cell--withRenderer div {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.word-break-all {
  word-break: break-all !important;
}

/* ============================================customer card css start ==================================*/
.amount-fund-wrapper {
  display: flex;
  margin-left: 24px;
  width: 100%;
  box-shadow: 0 0 14px #dedede;
  margin-top: 20px;
  text-align: center;
  border-radius: 5px;
}

.amount-fund-wrapper .customer-inner-cards {
  width: 25%;
  box-shadow: none !important;
}


.card-content {
  padding: 10px;
  position: relative;
}

.customer-inner-cards:last-child .card-content::after {
  display: none;
}

.card-content p {
  margin: 0;
  font-size: 14px;
  color: var(--secondary-main);
  font-weight: 600;
}

.card-content h2 {
  /* font-size: 22px; */
  margin: 0px;
  line-height: 28px;
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.card-content h2 span {
  font-size: 22px;
  padding-right: 2px;
  font-weight: 600;
}

.card-content:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  background: #c9c9c9;
  right: 0;
  height: 35px;
}

@media screen and (max-width:599px) {
  .customer-inner-cards:nth-child(2) .card-content::after {
    display: none;
  }
}

/* ============================================customer card css End ==================================*/
.cur-not-allowed {
  cursor: not-allowed !important;
}


/* Return and replace button desgin css [START] */
.replace-status {
  display: inline-block;
  background: #0062ff3b;
  padding: 1px 8px;
  color: #0062ff;
  font-weight: 400;
  font-size: 14px;
  display: inline-flex;
  margin: 0 -32px;
  border: 1px solid #0062ff;
}

.return-status {
  background: #ff4a0040;
  padding: 1px 8px;
  color: #ff4a00;
  font-weight: 400;
  font-size: 14px;
  display: inline-flex;
  margin: 0 -32px;
  border: 1px solid #ff4a00;
  display: inline;
}

/* Return and replace button desgin css [END] */

.track-progress-verticle {
  display: flex;
  align-items: flex-start;
  padding-top: 30px;
  width: 100%;
  overflow: auto;
}

.bg-extraLight {
  --bs-bg-opacity: 1;
  background-color: #f8f9fa !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.m-0 {
  margin: 0;
}

.track-progress-verticle li {
  list-style-type: none;
  border-top: 4px solid var(--white-800);
  display: inline-block;
  position: relative;
  text-align: center;
  padding: 30px 0 10px;
  width: 100%;
  min-width: 200px;
}

.track-progress-verticle li.done {
  border-top: 4px solid var(--primary-main);
}

.track-progress-verticle li .checkMark {
  position: absolute;
  top: -2px;
  width: 30px;
  height: 30px;
  background: var(--white);
  border-radius: 50%;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  font-weight: 700;
  color: var(--white);
  border: 4px solid var(--primary-main);
  background: var(--primary-main);
}

.opacity-50 {
  opacity: .5 !important;
}

.track-progress-verticle li .checkMark svg {
  width: auto;
  height: auto;
}

.order-image {
  width: 50px;
  height: 50px;
  object-fit: contain;
  display: block;
}

.cart-icon-21 {
  top: -5px;
  right: -5px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, .24);
  border-radius: 50%;
  background-color: var(--white);
  position: absolute;
  padding: 5px;
  width: 30px;
  height: 30px;
}

.cart-icon-21 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.w-100 {
  width: 100%;
}

.d-grid {
  display: grid;
}

@keyframes shake {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

svg.bell-con {
  animation-name: shake;
  animation: shake 6s 0.5s ease-in-out infinite;
}

.fs-28 {
  font-size: 28px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.customCard.card-size {
  padding: 10px;
}

.position {
  position: absolute;
  top: -16px;
  left: -17px;
}

.location_inner_table th {
  background: #cbcdcf;
  padding: 3px 17px;
  color: var(--black);
}

.location_inner_table td {
  background: #f5f5f5;
  padding: 3px 18px;
}

.location_table td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-o439hb-MuiTableCell-root {
  background: #ededed;
  border-bottom: 1px solid #d5cdcd;
}

.tooltip_table tbody tr {
  background: #2a2a2a;
}

.tooltip_table {
  border: 1px solid #303030;
}

.tooltip_table tbody tr td {
  padding: 8px;
  color: var(--white);
  border-bottom: none;
}


.tooltip_table th {
  background: #2a2a2a;
  font-size: 14px;
  border-bottom: 1px solid #303030;
  padding: 8px;
  font-weight: 700;
}

.css-1qyq6va-MuiTypography-root {
  padding-bottom: 10px !important;
}

.long-title {
  display: inline-block;
  width: 230px !important;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media screen and (max-width:1535px) {
  .column-spacing {
    padding: 0 12px !important;
  }
}



/* Date picker custom design */
/* .custom-desgin {} */

.custom-desgin .react-datepicker {
  border: none;
  box-shadow: 0 0 5px #7c7373;
}

.custom-desgin .react-datepicker__current-month {
  color: #cf1616;
  font-family: system-ui;
  text-align: left;
  padding: 11px 10px;
  font-weight: 400;
}

.custom-desgin .react-datepicker__header {
  padding: 0;
  border-bottom: 0;
}

.custom-desgin span.react-datepicker__navigation-icon.react-datepicker__navigation-icon--previous {
  right: 7px;
}

.custom-desgin span.react-datepicker__navigation-icon {
  top: 4px;
}

.custom-desgin .react-datepicker__navigation--next {
  right: -2px;
}

/* ::before {}

.custom-desgin .react-datepicker__navigation-icon--previous::before {} */

.custom-desgin .react-datepicker__navigation-icon::before {
  border-color: #706e6e;
}

.custom-desgin select.react-datepicker__month-select {
  border: 1px solid transparent;
  outline: none;
  width: 100%;
  padding: 5px 10px;
  background: #a9a9a91c;
}

.custom-desgin .react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--select {
  margin: 0 5px;
}

.custom-desgin .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
  display: flex;
  justify-content: space-between;
}

.custom-desgin select.react-datepicker__year-select {
  outline: none;
  border: 1px solid transparent;
  background: #a9a9a91c;
  width: 100%;
  padding: 5px 30px;
}

.custom-desgin .react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--select {
  margin: 0 5px;
}

.custom-desgin .react-datepicker__day-names {
  background: #a9a9a91c;
  margin: 4px 5px;
}

.custom-desgin .react-datepicker__month {
  background: #7c747426;
  margin: 4px 5px;
}

.custom-desgin .react-datepicker__day--keyboard-selected {
  background: #d92020;
  color: var(--white);
}

.custom-desgin .react-datepicker__day:hover {
  background: #cf1616;
  color: var(--white);
}

.custom-desgin .react-datepicker__navigation--previous {
  right: 18px !important;
  left: inherit;
}

.custom-desgin .react-datepicker__day--in-range {
  background: #cf1616;
}

.radius {
  border-radius: 0 !important;
  background-color: transparent !important;
  padding: 0 !important;
}

.custom-desgin .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .custom-desgin .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.custom-desgin .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.custom-desgin .react-datepicker__day--in-selecting-range,
.custom-desgin .react-datepicker__day--selected {
  background: #cf1616;

}

.slide {
  display: none;
}

.slide.active {
  display: block;
}

.slide img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.slider-btn {
  position: absolute !important;
  top: 50%;
}

.slider-btn.next {
  right: 10px;
}

.slider-btn.prev {
  left: 10px;
  transform: rotate(180deg);
}

.table-header {
  background-color: var(--table-header);
  color: var(--white);
}

.tablePagination {
  padding: 2px 16px;
  background-color: var(--table-header);
  color: var(--white);
}

.tablePagination .MuiTablePagination-toolbar,
.tablePagination .MuiTablePagination-toolbar svg {
  color: var(--white);
}

.tablePagination .MuiTablePagination-toolbar .MuiTablePagination-actions .Mui-disabled>svg {
  color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}


.sticky-column {
  position: sticky;
  right: 0;
  z-index: 10;
  background-color: var(--table-header);
}
.sticky-footer {
  position: sticky;
  right: 0;
  z-index: 10;
  background-color: var(--table-header);
}

.sticky-value-column {
  position: sticky;
  right: 0;
  z-index: 10;
  background-color: var(--table-column);

}

/* @media only screen and (max-width: 1760px) { */

.sticky-value-column::before,
.sticky-column::before {
  content: "";
  position: absolute;
  left: -6px;
  top: 0;
  bottom: -1px;
  width: 5px;
  border-right: 1px solid var(--borderColor);
  background: var(--sticky-gradient);
}
.sticky-footer::before {
  content: "";
  position: absolute;
  left: -6px;
  top: 0;
  bottom: -1px;
  width: 5px;
  border-right: 1px solid var(--borderColor);
  background: var(--sticky-gradient);
}
.sticky-value-column {
  position: sticky;
  right: 0;
  z-index: 10;
  background-color: var(--table-column);

}

/* @media only screen and (max-width: 1760px) { */

.sticky-value-column::before,
.sticky-value-column::before,
.sticky-column::before,
.sticky-column::before {
  content: "";
  position: absolute;
  left: -6px;
  top: 0;
  bottom: -1px;
  width: 5px;
  border-right: 1px solid var(--borderColor);
  background: var(--sticky-gradient);
}

/* } */

.loading-top {
  position: relative;
}

.loading-top::after {
  content: "LOADING...";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgb(22 59 114 / 9%);
  left: 0;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: 700;
  text-shadow: 1px 0.5px 3px var(--black);
  text-transform: uppercase;
}
.coin {
  background-color: rgb(247, 175, 20);
  border-radius: 50%;
  margin: -3px 0px;
}
.mr-3{
  margin-right: 5px;
}